import { useState, useEffect } from "react";
import { Row, Spinner, Container } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../utils";
import MyOrderTable from "./MyQoutesTable";
import Filter from "./Filter";

const URL_API = process.env.REACT_APP_URL_API;

export default function MyQuotes() {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const [myQuotes, setMyQuotes] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  const getMyQuotes = async (url = null) => {
    const finalUrl = url || `${URL_API}api/quote/`;

    await fletesAPI
      .get(finalUrl)
      .then((e) => {
        setMyQuotes(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const fetchQuotesFilter = async (filter) => {
    const finalUrl = `${URL_API}api/quote/`;

    setLoading(true);
    try {
      const response = await fletesAPI.get(finalUrl, {
        params: {
          reference: filter?.reference ?? undefined,
          origin_city_id: filter?.origin_city_id ?? undefined,
          destination_city_id: filter?.destination_city_id ?? undefined,
          start_date: filter?.start_date ?? undefined,
          end_date: filter?.end_date ?? undefined,
          status: filter?.status ?? undefined,
        },
      });
      setMyQuotes(response.data);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (myQuotes === null) {
      toast.promise(getMyQuotes(), {
        loading: "Cargando estimaciones...",
        success: "Estimaciones cargadas",
        error: "Error al cargar estimaciones",
      });
    }
  }, [myQuotes]);

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Mis estimaciones</h1>
          <p>Estas son estimaciones de transportistas y estan sujetas a disponibilidad, si deseas confirmar tu flete, porfavor contacta al transportista directamente y el te creara un "Flete" que podras despues ver en "Mis Fletes".</p>
        </Row>
        <hr />
        <Filter sendRequest={fetchQuotesFilter} />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <MyOrderTable
            reloadQoutes={getMyQuotes}
            quotes={myQuotes}
            isMobile={isMobile}
          />
        )}
      </Container>
    </>
  );
}
