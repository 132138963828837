import imageExample from "../assets/images/example-bodega.webp";
import image1 from "../assets/images/bodega1.jpg";
import image2 from "../assets/images/bodega2.jpg";
import image3 from "../assets/images/bodega3.jpg";
import image4 from "../assets/images/bodega4.jpg";
import image5 from "../assets/images/bodega5.jpg";
import image6 from "../assets/images/bodega6.jpg";
import image7 from "../assets/images/bodega7.jpg";

export * from "./errorCodes";
export * from "./simplifyDecimal";

export const generarColorHexadecimal = () => {
  let color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color.padStart(6, "0")}`;
}

export const testJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkMobile = (setIsMobile) => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  setIsMobile(check);
  return check;
};

export const defaultDays = {
  monday: {
    enabled: true,
    start: "08:00",
    end: "20:00",
  },
  tuesday: {
    enabled: true,
    start: "08:00",
    end: "20:00",
  },
  wednesday: {
    enabled: true,
    start: "08:00",
    end: "20:00",
  },
  thursday: {
    enabled: true,
    start: "08:00",
    end: "20:00",
  },
  friday: {
    enabled: true,
    start: "08:00",
    end: "20:00",
  },
  saturday: {
    enabled: false,
    start: "",
    end: "",
  },
  sunday: {
    enabled: false,
    start: "",
    end: "",
  },
};

export const daysToJsonToString = (data) => {
  const days = {
    monday: {
      enabled: data.monday,
      start: data.monday_start,
      end: data.monday_end,
    },
    tuesday: {
      enabled: data.tuesday,
      start: data.tuesday_start,
      end: data.tuesday_end,
    },
    wednesday: {
      enabled: data.wednesday,
      start: data.wednesday_start,
      end: data.wednesday_end,
    },
    thursday: {
      enabled: data.thursday,
      start: data.thursday_start,
      end: data.thursday_end,
    },
    friday: {
      enabled: data.friday,
      start: data.friday_start,
      end: data.friday_end,
    },
    saturday: {
      enabled: data.saturday,
      start: data.saturday_start,
      end: data.saturday_end,
    },
    sunday: {
      enabled: data.sunday,
      start: data.sunday_start,
      end: data.sunday_end,
    },
  };
  return JSON.stringify(days);
};

export const days = [
  { value: "0", label: "Domingo", name: "sunday" },
  { value: "1", label: "Lunes", name: "monday" },
  { value: "2", label: "Martes", name: "tuesday" },
  { value: "3", label: "Miércoles", name: "wednesday" },
  { value: "4", label: "Jueves", name: "thursday" },
  { value: "5", label: "Viernes", name: "friday" },
  { value: "6", label: "Sábado", name: "saturday" },
];

export const optionsCertificatedStatus = [
  { value: 0, label: "Borrador" },
  { value: 1, label: "Cliente en espera" },
  { value: 2, label: "Revisión de soporte pendiente" },
  { value: 3, label: "Aceptado" },
  { value: 4, label: "Solicitud de más información" },
  { value: 5, label: "Cancelado" },
];

export const statusTaskColor = (e) => {
  const index = parseInt(e) - 1;
  const status = [
    "secondary",
    "warning",
    "success",
    "danger",
  ];

  return status[index] ? status[index] : "secondary";
}

export const statusTaskLabel = (e) => {
  const index = parseInt(e) - 1;
  const status = [
    "Pendiente",
    "Pendiente verificación",
    "Completado",
    "Cancelado",
  ];

  return status[index] ? status[index] : "Pendiente";
}

export const statusOrderColor = (e) => {
  const index = parseInt(e) - 1;
  const status = [
    "secondary",
    "info",
    "success",
    "danger",
  ];
  return status[index] ? status[index] : "secondary";
}

export const statusOrderLabel = (e) => {
  const index = parseInt(e) - 1;
  const status = [
    "Pendiente",
    "Pendiente verificación",
    "Completado",
    "Cancelado",
  ];
  return status[index] ? status[index] : "Pendiente";
}

export const typeVal = (e) => {
  switch (e) {
    case 0:
      return "secondary";
    case 1:
      return "warning";
    case 2:
      return "info";
    case 3:
      return "success";
    default:
      return "secondary";
  }
};
export const textVal = (e) => {
  switch (e) {
    case 0:
      return "No publicado";
    case 1:
      return "Publicado";
    case 2:
      return "En curso";
    case 3:
      return "Completado";
    case 4:
      return "Cancelado";
    default:
      return "No publicado";
  }
};

export const statusOrder = [
  { value: 1, label: "Requiere información", color: "secondary" },
  { value: 2, label: "Contactanos", color: "warning" },
  { value: 3, label: "En proceso", color: "primary" },
  { value: 4, label: "Completado", color: "success" },
  { value: 5, label: "Cancelado", color: "danger" },
  { value: 6, label: "Archivado", color: "secondary" },
];

export const statusLabel = (e) => {
  const num = parseInt(e);
  const statusItem = statusOrder.find(item => item.value === num);
  return statusItem ? statusItem.label : "Requiere información";
};
export const statusColor = (e) => {
  const num = parseInt(e);
  const statusItem = statusOrder.find(item => item.value === num);
  return statusItem ? statusItem.color : "secondary";
};

export const typeUser = (e) => {
  switch (e) {
    case "0":
      return "success";
    case "1":
      return "secondary";
    case "2":
      return "secondary";
    default:
      return "secondary";
  }
};
export const textUser = (e) => {
  switch (e) {
    case "0":
      return "Dueño";
    case "1":
      return "Usuario";
    case "2":
      return "Conductor";
    default:
      return "User";
  }
};

export const typeInvit = (e) => {
  switch (e) {
    case false:
      return "secondary";
    case true:
      return "success";
    default:
      return "secondary";
  }
};
export const textInvit = (e) => {
  switch (e) {
    case false:
      return "pendiente";
    case true:
      return "usado";
    default:
      return "pendiente";
  }
};

export const statusOption = [
  { value: 0, label: "Pendiente confirmación del conductor" },
  { value: 1, label: "Conductor en ruta para la recoger la carga" },
  { value: 2, label: "Conductor en proceso de carga" },
  { value: 3, label: "Conductor en ruta para la entrega" },
  { value: 4, label: "Conductor en proceso de descarga" },
  { value: 5, label: "Carga entregada" },
];

export const currencyOption = [
  { value: "MXN", label: "MXN" },
  { value: "USD", label: "USD" },
];

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const fullNameCity = (city) => {
  return `${city.name}, ${city.state.name}, ${city.state.country.name}`;
};

export const reformatCity = (e) => ({
  value: e.id,
  label: `${e.name}, ${e.state.name}, ${e.state.country.name}`,
});

export const reformatDriver = (e) => ({
  value: e.id,
  label:
    e.profile.first_name + " " + e.profile.last_name + " | " + e.profile.email,
});

export const reformatDriverDefaultVal = (e) => ({
  value: e.driver_id,
  label:
    e.driver_profile.first_name +
    " " +
    e.driver_profile.last_name +
    " | " +
    e.driver_profile.email,
});

export const formatServiceDays = {
  label: "24/7 | 7 días a la semana",
  fullTime: true,
  days: {
    monday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    tuesday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    wednesday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    thursday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    friday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    saturday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
    sunday: {
      enabled: true,
      justification: "",
      from: "00:00",
      to: "23:59",
    },
  },
};

export const getHours = ({ data, prefix = "" }) => {
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const hours = {};

  hours.fullTime = data.fullTime || false;

  days.forEach((day) => {
    if (data[`${prefix}${day}`]) {
      hours[day] = {
        enabled: true,
        from: data[`${prefix}${day}_start`],
        to: data[`${prefix}${day}_end`],
      };
    } else {
      hours[day] = {
        enabled: false,
        from: "",
        to: "",
      };
    }
  });

  return JSON.stringify(hours);
};

export const getAddressData = ({data, prefix = ""}) => {
  return {
    name: Boolean(data[`${prefix}name`]) ? data[`${prefix}name`] : "",
    address_line1: Boolean(data[`${prefix}address_line1`]) ? data[`${prefix}address_line1`] : "",
    address_line2: Boolean(data[`${prefix}address_line2`]) ? data[`${prefix}address_line2`] : "",
    zip_code: Boolean(data[`${prefix}zip_code`]) ? data[`${prefix}zip_code`] : "",
    phone: Boolean(data[`${prefix}phone`]) ? data[`${prefix}phone`] : "",
    city_id: Boolean(data[`${prefix}city`]) ? data[`${prefix}city`].value : "",
    latitude: Boolean(data[`${prefix}location`]) ? parseFloat(data[`${prefix}location`].lat) ? data[`${prefix}location`].lat.toString() : "0" : "0",
    longitude: Boolean(data[`${prefix}location`]) ? parseFloat(data[`${prefix}location`].lng) ? data[`${prefix}location`].lng.toString() : "0" : "0",
    hours: getHours({data, prefix}),
    notes: Boolean(data[`${prefix}notes`]) ? data[`${prefix}notes`] : "",
  };
};

export const warehousesArr = [
  {
    name: "Warehouse 1",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 1,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.49,
    latitude: 27.6,
    services: [0, 1, 2, 3],
    gallery: [image1, image2, image3, image4, image5, image6, image7],
  },
  {
    name: "Warehouse 2",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.52,
    latitude: 27.6,
    services: [2],
    gallery: [image1, image2, image3, image4, image5, image6, image7],
  },
  {
    name: "Warehouse 3",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.51,
    latitude: 27.61,
    services: [2, 3],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
  {
    name: "Warehouse 4",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.535,
    latitude: 27.622,
    services: [0, 1],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
  {
    name: "Warehouse 5",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.538,
    latitude: 27.626,
    services: [3],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
  {
    name: "Warehouse 6",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.531,
    latitude: 27.622,
    services: [1, 3],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
  {
    name: "Warehouse 7",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.53,
    latitude: 27.62,
    services: [0, 2],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
  {
    name: "Warehouse 8",
    address: "123 Main St",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    country: "USA",
    id: 2,
    horario: "Lunes a Viernes de 9:00 a 18:00",
    image: imageExample,
    longitude: -99.535,
    latitude: 27.628,
    services: [3],
    gallery: [
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
      imageExample,
    ],
  },
];

export const getMonthLength = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1
  return new Date(year, month, 0).getDate();
}


export const setHours = ({ data, prefix = "", setValue }) => {
  const dataParsed = JSON.parse(data);
  if ( dataParsed.fullTime ) {
    setValue(`${prefix}fullTime`, dataParsed.fullTime);
  }
  if ( dataParsed.monday.enabled ) {
    setValue(`${prefix}monday`, dataParsed.monday.enabled);
    setValue(`${prefix}monday_start`, dataParsed.monday.from);
    setValue(`${prefix}monday_end`, dataParsed.monday.to);
  }
  if ( dataParsed.tuesday.enabled ) {
    setValue(`${prefix}tuesday`, dataParsed.tuesday.enabled);
    setValue(`${prefix}tuesday_start`, dataParsed.tuesday.from);
    setValue(`${prefix}tuesday_end`, dataParsed.tuesday.to);
  }
  if ( dataParsed.wednesday.enabled ) {
    setValue(`${prefix}wednesday`, dataParsed.wednesday.enabled);
    setValue(`${prefix}wednesday_start`, dataParsed.wednesday.from);
    setValue(`${prefix}wednesday_end`, dataParsed.wednesday.to);
  }
  if ( dataParsed.thursday.enabled ) {
    setValue(`${prefix}thursday`, dataParsed.thursday.enabled);
    setValue(`${prefix}thursday_start`, dataParsed.thursday.from);
    setValue(`${prefix}thursday_end`, dataParsed.thursday.to);
  }
  if ( dataParsed.friday.enabled ) {
    setValue(`${prefix}friday`, dataParsed.friday.enabled);
    setValue(`${prefix}friday_start`, dataParsed.friday.from);
    setValue(`${prefix}friday_end`, dataParsed.friday.to);
  }
  if ( dataParsed.saturday.enabled ) {
    setValue(`${prefix}saturday`, dataParsed.saturday.enabled);
    setValue(`${prefix}saturday_start`, dataParsed.saturday.from);
    setValue(`${prefix}saturday_end`, dataParsed.saturday.to);
  }
  if ( dataParsed.sunday.enabled ) {
    setValue(`${prefix}sunday`, dataParsed.sunday.enabled);
    setValue(`${prefix}sunday_start`, dataParsed.sunday.from);
    setValue(`${prefix}sunday_end`, dataParsed.sunday.to);
  }
};