import { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import RenderSpace from "../../../fyb_components/results/RenderSpace";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import { fullNameCity, erroresCodes } from "../../../utils";
import useFetch from "../../../hooks/useFetch";
import BreadCrumbs from "./BreadCrumbs";

import EditOrder from "./views/EditOrder";
import Comments from "./views/Comments";
import Files from "./views/Files";
import Tasks from "./views/Tasks";
import Details from "./views/Details";
import ContactFreight from "./views/ContactFreight";

const viewList = {
  contact: "contact",
  edit: "edit",
  comments: "comments",
  files: "files",
  tasks: "tasks",
  details: "details",
};

export default function MyOrderById({ carrier }) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [viewSelected, setViewSelected] = useState("edit");
  const { fletesAPI } = useFetch();
  const { id, view } = useParams();
  const navigate = useNavigate();

  const getOrder = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/freight/${id}/`);
      setOrder(response.data);
    }
    catch (e) {
      throw e;
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (view !== undefined) {
      setViewSelected(view);
    }
  }, [view]);

  useEffect(() => {
    if (order === null) {
      toast.promise(getOrder(), {
        loading: "Cargando...",
        success: "Orden cargada",
        error: "Error al cargar la orden",
      });
    }
  }, [order]);

  return (
    <>
      <Container>
        <Row className="mt-5">
          {!loading && (
            <>
              <BreadCrumbs
                breadcrumbs={[
                  {
                    label: order?.reference ? `No. ${order.reference}` : "Referencia",
                  },
                  {
                    label: order?.origin_address?.city
                      ? `${fullNameCity(order.origin_address.city)} ${moment(order?.quote?.pickup_time).format("DD/MM/YYYY HH:mm").toString()}`
                      : "Cuidad de origen",
                  },
                  {
                    label: order?.destination_address?.city
                      ? `${fullNameCity(order.destination_address.city)} ${moment(order?.quote?.delivery_time).format("DD/MM/YYYY HH:mm").toString()}`
                      : "Cuidad de destino",
                  },
                ]}
              />
            </>
          )}
        </Row>
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Resumen",
                  active: viewSelected === viewList.contact,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.contact}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Detalles",
                  active: viewSelected === viewList.details,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.details}`, {
                      replace: true,
                    });
                  },
                  disable: !carrier,
                },
                {
                  label: "Editar",
                  active: viewSelected === viewList.edit,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.edit}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Comentarios",
                  active: viewSelected === viewList.comments,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.comments}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Archivos",
                  active: viewSelected === viewList.files,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.files}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Tareas",
                  active: viewSelected === viewList.tasks,
                  onClick: () => {
                    navigate(`/dashboard/my_order/${id}/${viewList.tasks}`, {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && (
              <RenderSpace>
                <Spinner animation="border" variant="primary" />
              </RenderSpace>
            )}

            {!loading && viewSelected === viewList.contact && (
              <ContactFreight order={order} getOrder={getOrder} />
            )}
            {!loading && viewSelected === viewList.edit && (
              <EditOrder order={order} getOrder={getOrder} />
            )}
            {!loading && viewSelected === viewList.comments && (
              <Comments order={order} />
            )}
            {!loading && viewSelected === viewList.files && (
              <Files order={order} getOrder={getOrder} />
            )}
            {!loading && viewSelected === viewList.tasks && (
              <Tasks order={order} carrier={carrier} />
            )}
            {!loading && viewSelected === viewList.details && carrier && (
              <Details order={order} getOrder={getOrder}  />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
